<template>
  <div>
    <div v-if="!showLoader">
      <m-page-title title="Amazon Orders" />
      <div class="col-12">
        <b-row class="align-items-center my-4">
          <b-colxx md="2" sm="12">
            <b-form-group
              id="search-from-fieldset"
              label="From"
              label-for="search-from-date"
              class="field-label-form-group"
            >
              <b-form-input id="search-from-date" v-model="searchFromDate" type="date" />
            </b-form-group>
          </b-colxx>
          <b-colxx md="2" sm="12">
            <b-form-group
              id="search-to-fieldset"
              label="To"
              label-for="search-to-date"
              class="field-label-form-group"
            >
              <b-form-input id="search-to-date" v-model="searchToDate" type="date" />
            </b-form-group>
          </b-colxx>
          <b-colxx md="3" sm="12">
            <b-button variant="success" @click="getAmazonFinancialEventGroups()">Search</b-button>
            <b-button variant="info" @click="clearSearchFilters()">Clear</b-button>
            <b-button variant="secondary" @click="exportToExcel()">Generate Report</b-button>
          </b-colxx>

        </b-row>

        <div class="card-body">
          <b-table
            id="amazon-financial-event-group-table"
            ref="__amazonFinancialEventGroupTable"
            responsive
            head-variant="dark"
            :items="amazonFinancialEventGroups"
            :fields="amazon_financial_event_group_list_fields"
            per-page="0"

          >
            <template #cell(view)="row">
              <i
                class="fas fa-eye text-default icon-pointer"

                @click="getFinancialEventGroupDetails(row.item.id)"
              />
            </template>
          </b-table>
        </div>
        <b-pagination
          v-model="current_page"
          :total-rows="total_rows"
          :per-page="per_page"
          :number-of-pages="totalPages"
          size="sm"
          class="my-0"
          aria-controls="amazon-financial-event-group-table"
          @input="getAmazonFinancialEventGroups(current_page)"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import django_session from "@/api/django_session";
import { mapGetters } from "vuex";
import MSpinnyMessage from "@/components/loader/MSpinnyMessage.vue";
import BaseCheckbox from "@/components/input/BaseCheckbox.vue";
import { utils, writeFile } from 'xlsx';

export default {
  name: "AmazonTransactions",

  components: {},

  data() {
    return {
      gettingData: false,
      amazonFinancialEventGroups: [],
      amazon_financial_event_group_list_fields: [
        { key: "view", label: "" },
        { key: "financial_event_group_start", label: "Group Start" },
        { key: "financial_event_group_end", label: "Group End" },
        { key: "fund_transfer_date", label: "Fund Transfer Date" },
        { key: "trace_id", label: "Trace ID" },
        { key: "account_tail", label: "Account Tail" },
        { key: "beginning_balance_amount", label: "Beginning Balance Amount" },
        { key: "beginning_balance_currency_code", label: "Currency Code" },
        { key: "original_total_amount", label: "Original Total Amount" },
        { key: "original_total_currency_code", label: "Currency Code" },
      ],
      // searchKeyword: "",
      searchFromDate: null,
      searchToDate: null,
      // isNotReconciled: false,
      // isNoSageSalesOrderId: false,
      per_page: 10,
      total_rows: 0,
      current_page: 1,
      previousPageLink: null,
      nextPageLink: null,
      showOrderDetailsModal: false,
      financialEventGroupDetails: {},
      loadingFinancialEventGroupDetails: false,
      showAlert: false,
      alertVariant: "",
      alertMessage: "",

    };
  },

  mounted() {
    document.title = "Amazon Transactions";
  },


  computed: {
    isPreviousPageDisabled() {
      return this.current_page === 1;
    },
    isNextPageDisabled() {
      return this.current_page === this.total_rows;
    },
    totalPages() {
      return Math.ceil(this.total_rows / this.per_page);
    },

    hasPromotions() {
      if (!this.orderDetails.order_items) return false;
      return this.orderDetails.order_items.some(
        (item) =>
          item.promotion_discount_amount > 0 ||
          (item.promotion_ids && item.promotion_ids.length > 0)
      );
    },
    orderIsFlagged() {
      return(item => flaggedProvinces.includes(item.shipping_address_state_or_region));
    },
    ...mapGetters("common", ["showLoader"]),
  },

  methods: {
    async getAmazonFinancialEventGroups() {
      this.$store.dispatch("common/showLoader", true);
      await django_session
        .get("erp/amazon/list-financial-event-groups/", {

          params: {
            page: this.current_page,
            searchFromDate: this.searchFromDate,
            searchToDate: this.searchToDate,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.results) {
            response.data.results.forEach((item) => {
              item.financial_event_group_start = new Date(item.financial_event_group_start).toLocaleDateString();
              if (item.financial_event_group_end) {
                item.financial_event_group_end = new Date(item.financial_event_group_end).toLocaleDateString();
              }
              if (item.fund_transfer_date) {
                item.fund_transfer_date = new Date(item.fund_transfer_date).toLocaleDateString();
              }
            });

            this.amazonFinancialEventGroups = response.data.results;
            this.total_rows = response.data.count;
            this.current_page = response.data.page;
            this.previousPageLink = response.data.links.previous;
            this.nextPageLink = response.data.links.next;
          } else {
            response.data.forEach((order) => {
              order.purchase_date = new Date(order.purchase_date).toUTCString();
            });
            this.amazonOrders = response.data;
            this.total_rows = 0;
            this.current_page = 1;
            this.previousPageLink = null;
            this.nextPageLink = null;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.current_page = 1;
            this.total_rows = 0;
            this.amazonOrders = [];
            // this.getAmazonFinancialEventGroups();
          }
        })

        .finally(() => {
          this.$store.dispatch("common/showLoader", false);
        });
    },

    async getFinancialEventGroupDetails(financialEventGroupId) {
      // this.loadingFinancialEventGroupDetails = true;
      await django_session.get(`erp/amazon/get-financial-event-group-details/${financialEventGroupId}/`).then((response) => {
        console.log(response.data);
        // this.financialEventGroupDetails = response.data;
        // this.loadingFinancialEventGroupDetails = false;
      }).catch((error) => {
        console.error("Error fetching financial event group details:", error);
      });
    },


    clearSearchFilters() {
      this.searchKeyword = "";
      this.searchFromDate = null;
      this.searchToDate = null;
      this.amazonOrders = [];
      this.total_rows = 0;
      this.current_page = 1;
      this.previousPageLink = null;
      this.nextPageLink = null;
      this.isReconciled = null;
      this.noSageSalesOrderId = null;
    },

    // exportToExcel() {
    //   console.log("Exporting to Excel");
    //   this.$store.dispatch("common/showLoader", true);
    //   django_session.get("erp/amazon/get-orders/", {
    //     params: {
    //       searchKeyword: this.searchKeyword,
    //       searchFromDate: this.searchFromDate,
    //       searchToDate: this.searchToDate,
    //       isReconciled: this.isReconciled,
    //       noSageSalesOrderId: this.noSageSalesOrderId,
    //       page_size: 0,
    //     },
    //   }).then((response) => {
    //     let fileName = "Amazon Orders.xlsx";
        
    //     // Transform the data into rows, with order items expanded
    //     const rows = [];
    //     response.data.forEach(order => {
    //       // For each order item, create a row with both order and item details
    //       order.order_items.forEach(item => {
    //         rows.push([
    //           order.amazon_order_id,
    //           order.sage_sales_order_id,
    //           order.purchase_date ? new Date(order.purchase_date).toUTCString() : "",
    //           order.reconciled_date ? new Date(order.reconciled_date).toUTCString() : "",
    //           order.order_total_amount.toFixed(2),
    //           order.gst_total ? order.gst_total.toFixed(2) : "0.00",
    //           order.pst_total ? order.pst_total.toFixed(2) : "0.00",
    //           order.hst_total ? order.hst_total.toFixed(2) : "0.00",
    //           order.shipping_price_amount || "0.00",
    //           order.shipping_tax_amount || "0.00",
    //           order.shipping_discount_amount || "0.00",
    //           order.shipping_address_city,
    //           order.shipping_address_state_or_region,
    //           // Item specific details
    //           item.seller_sku,
    //           item.asin,
    //           item.title,
    //           item.quantity_ordered,
    //           item.quantity_shipped,
    //           item.item_price_amount ? item.item_price_amount.toFixed(2) : "0.00",
    //           item.item_tax_amount ? item.item_tax_amount.toFixed(2) : "0.00",
    //           item.gst_amount ? item.gst_amount.toFixed(2) : "0.00",
    //           item.pst_amount ? item.pst_amount.toFixed(2) : "0.00",
    //           item.hst_amount ? item.hst_amount.toFixed(2) : "0.00",
    //           item.promotion_discount_amount ? item.promotion_discount_amount.toFixed(2) : "0.00"
    //         ]);
    //       });
    //     });

    //     // Create worksheet with headers
    //     const ws = utils.aoa_to_sheet([
    //       [
    //         // Order level headers
    //         "Amazon Order #", 
    //         "Sage Order #", 
    //         "Purchase Date", 
    //         "Reconciled Date", 
    //         "Order Total", 
    //         "Order GST", 
    //         "Order PST", 
    //         "Order HST", 
    //         "Order Shipping", 
    //         "Order Shipping Tax", 
    //         "Order Shipping Discount", 
    //         "City", 
    //         "Prov",
    //         // Item level headers
    //         "SKU",
    //         "ASIN",
    //         "Title",
    //         "Qty Ordered",
    //         "Qty Shipped",
    //         "Item Price",
    //         "Item Tax",
    //         "Item GST",
    //         "Item PST",
    //         "Item HST",
    //         "Item Promotion Discount"
    //       ],
    //       ...rows
    //     ]);

    //     // Set column widths
    //     ws['!cols'] = [
    //       { wch: 20 }, // Amazon Order #
    //       { wch: 15 }, // Sage Order #
    //       { wch: 30 }, // Purchase Date
    //       { wch: 30 }, // Reconciled Date
    //       { wch: 12 }, // Order Total
    //       { wch: 10 }, // Order GST
    //       { wch: 10 }, // Order PST
    //       { wch: 10 }, // Order HST
    //       { wch: 12 }, // Order Shipping
    //       { wch: 12 }, // Order Shipping Tax
    //       { wch: 15 }, // Order Shipping Discount
    //       { wch: 20 }, // City
    //       { wch: 10 }, // Prov
    //       { wch: 15 }, // SKU
    //       { wch: 15 }, // ASIN
    //       { wch: 50 }, // Title
    //       { wch: 12 }, // Qty Ordered
    //       { wch: 12 }, // Qty Shipped
    //       { wch: 12 }, // Item Price
    //       { wch: 12 }, // Item Tax
    //       { wch: 12 }, // Item GST
    //       { wch: 12 }, // Item PST
    //       { wch: 12 }, // Item HST
    //       { wch: 15 }  // Item Promotion Discount
    //     ];

    //     const wb = utils.book_new();
    //     utils.book_append_sheet(wb, ws, "Amazon Orders");
    //     writeFile(wb, fileName);
    //   }).catch((error) => {
    //     console.error("Export failed:", error);
    //   }).finally(() => {
    //     this.$store.dispatch("common/showLoader", false);
    //   });
    // },

    async getOrderDetails(orderId) {
      this.showOrderDetailsModal = true;
      this.loadingOrderDetails = true;
      await django_session.get(`erp/amazon/get-order/${orderId}/`).then((response) => {
        console.log(response.data);
        this.orderDetails = response.data;
        this.loadingOrderDetails = false;
      }).catch((error) => {
        this.loadingOrderDetails = false;
      });
    },

    updateReconciliationCheckbox(orderId) {
      console.log(orderId);
      let order = this.amazonOrders.find(order => order.id === orderId);
      order.is_reconciled_yn = !order.is_reconciled_yn;
      console.log(order);
      this.orderDetails = order;
      this.saveOrderDetails();
    },

    async saveOrderDetails() {
      this.loadingOrderDetails = true;
      await django_session.put(`erp/amazon/get-order/${this.orderDetails.id}/`, this.orderDetails).then((response) => {
        this.orderDetails = response.data;
        let orderIndex = this.amazonOrders.findIndex(order => order.id === this.orderDetails.id);
        console.log(orderIndex);
        this.amazonOrders[orderIndex] = this.orderDetails;
        this.loadingOrderDetails = false;
        this.showAlert = true;
        this.alertVariant = "success";
        this.alertMessage = "Order details updated successfully";
      }).catch((error) => {
        this.loadingOrderDetails = false;
        this.showAlert = true;
        this.alertVariant = "danger";
        this.alertMessage = "Error updating order details";
      });
    },
  },
};
</script>

<style scoped>
.order-details .card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-item:last-child {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.promotion-item {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 4px;
  border-left: 4px solid #6c757d;
}

.totals {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
