<template>
  <div>
    <div v-if="!showLoader">
      <m-page-title title="Amazon Refunds" />
      <div class="col-12">
        <b-row class="align-items-center my-4">
          <b-colxx md="3" sm="12">
            <b-form-group
              id="search-from-fieldset"
              label="From"
              label-for="search-from-date"
              class="field-label-form-group"
            >
              <b-form-input id="search-from-date" v-model="searchFromDate" type="date" />
            </b-form-group>
          </b-colxx>
          <b-colxx md="3" sm="12">
            <b-form-group
              id="search-to-fieldset"
              label="To"
              label-for="search-to-date"
              class="field-label-form-group"
            >
              <b-form-input id="search-to-date" v-model="searchToDate" type="date" />
            </b-form-group>
          </b-colxx>
          <b-colxx md="3" sm="12">
            <b-button variant="success" @click="getAmazonRefunds()">Search</b-button>
            <b-button variant="info" @click="clearSearchFilters()">Clear</b-button>
            <b-button variant="secondary" @click="exportToExcel()">Generate Report</b-button>
          </b-colxx>
        </b-row>
        <div class="card-body">
          <b-alert variant="danger" v-if="showErrorMessage" dismissible @dismissed="showErrorMessage = false" show>{{ errorMessage }}</b-alert>
          <b-table
            id="amazon-order-table"
            ref="__amazonRefundTable"
            responsive
            head-variant="dark"
            :items="amazonRefunds"
            :fields="amazon_refund_list_fields"
            per-page="0"
            :tbody-tr-class="rowCallback"

          >
            <template #cell(sage_reference_number)="row">
              <div class="d-flex align-items-center">
                <b-form-input v-model="row.item.sage_reference_number" />
                <span class="i fas fa-save ml-2" type="button" @click="updateTransactionSageReferenceNumber(row.item.id)"></span>
              </div>
            </template>
            <template #cell(product_details)="row">


              <div v-for="product in row.item.product_details" :key="product.sku">
                <span class="d-block">
                  <tr><strong>SKU:</strong> {{ product.sku }}</tr>
                  <tr><strong>Description:</strong> {{ product.description }}</tr>
                  <tr><strong>Quantity:</strong> {{ product.quantity }}</tr>
                </span
                >
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          v-model="current_page"
          :total-rows="total_rows"
          :per-page="per_page"
          :number-of-pages="totalPages"
          size="sm"
          class="my-0"
          aria-controls="amazon-order-table"
          @input="getAmazonRefunds(current_page)"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import django_session from "@/api/django_session";
import { mapGetters } from "vuex";
import { utils, writeFile } from 'xlsx';

export default {
  name: "AmazonRefunds",


  components: {},

  data() {
    return {
      gettingData: false,
      amazonRefunds: [],
      amazon_refund_list_fields: [
        { key: "sage_reference_number", label: "Credit #", thStyle: { width: "200px" } },
        { key: "posted_date", label: "Posted Date" },
        { key: "order_id", label: "Order ID" },
        { key: "product_details", label: "Product Details" },
        { key: "product_charges", label: "Product Charges" },

        { key: "refunded_sales", label: "Refunded Sales" },
        { key: "tax", label: "Tax" },
        { key: "amazon_fees", label: "Amazon Fees" },
        { key: "digital_services_fee", label: "Digital Services Fee" },
        { key: "promo_rebates", label: "Promo Rebates" },
        { key: "refunded_expenses", label: "Refunded Expenses" },
        { key: "total_amount", label: "Total Amount" },
      ],
      searchKeyword: "",
      searchFromDate: null,
      searchToDate: null,
      isNotReconciled: false,
      isNoSageSalesOrderId: false,
      per_page: 10,
      total_rows: 0,
      current_page: 1,
      previousPageLink: null,
      nextPageLink: null,
      showOrderDetailsModal: false,
      orderDetails: {},
      loadingOrderDetails: false,
      showAlert: false,
      alertVariant: "",
      alertMessage: "",
      savedRowId: null,
      savedRowClassName: "",
      showErrorMessage: false,
      errorMessage: "",
    };
  },

  mounted() {
    document.title = "Amazon Refunds";
    // this.getAmazonRefunds();
  },

  computed: {
    isPreviousPageDisabled() {
      return this.current_page === 1;
    },
    isNextPageDisabled() {
      return this.current_page === this.total_rows;
    },
    totalPages() {
      return Math.ceil(this.total_rows / this.per_page);
    },

    hasPromotions() {
      if (!this.orderDetails.order_items) return false;
      return this.orderDetails.order_items.some(
        (item) =>
          item.promotion_discount_amount > 0 ||
          (item.promotion_ids && item.promotion_ids.length > 0)
      );
    },
    orderIsFlagged() {
      return(item => flaggedProvinces.includes(item.shipping_address_state_or_region));
    },
    ...mapGetters("common", ["showLoader"]),
  },

  methods: {
    async getAmazonRefunds() {
      if (this.searchFromDate == null) {
        alert("Leaving the from date blank will result in a long running query. Please set a date.");
        return;
      }
      this.$store.dispatch("common/showLoader", true);
      await django_session

        .get("erp/amazon/get-refunds/", {

          params: {
            page: this.current_page,
            searchKeyword: this.searchKeyword,
            searchFromDate: this.searchFromDate,
            searchToDate: this.searchToDate,
          },
        })
        .then((response) => {
          if (response.data.results) {
            response.data.results.forEach((order) => {
              order.purchase_date = new Date(order.purchase_date).toUTCString();
            });
            this.amazonRefunds = response.data.results;
            this.total_rows = response.data.count;
            this.current_page = response.data.page;
            this.previousPageLink = response.data.links.previous;
            this.nextPageLink = response.data.links.next;
          } else {
            response.data.forEach((order) => {
              order.purchase_date = new Date(order.purchase_date).toUTCString();
            });
            this.amazonRefunds = response.data;
            this.total_rows = 0;
            this.current_page = 1;
            this.previousPageLink = null;
            this.nextPageLink = null;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.current_page = 1;
            this.total_rows = 0;
            this.amazonRefunds = [];
            this.getAmazonRefunds();
          }
        })
        .finally(() => {
          this.$store.dispatch("common/showLoader", false);
        });
    },

    async updateTransactionSageReferenceNumber(transactionId) {
      console.log(transactionId);
      // this.savedRowId = transactionId;
      await django_session.put(`erp/amazon/update-transaction-sage-reference-number/${transactionId}/`, {
        sage_reference_number: this.amazonRefunds.find(refund => refund.id === transactionId).sage_reference_number,
      }).then((response) => {
        this.savedRowId = transactionId;
        this.savedRowClassName = 'highlight-saved-success';
        setTimeout(() => {
          this.savedRowId = null;
          this.savedRowClassName = '';
        }, 2000);

      }).catch((error) => {
        this.savedRowId = transactionId;
        this.savedRowClassName = 'highlight-saved-danger';
        this.errorMessage = error.response.data;
        this.showErrorMessage = true;
        setTimeout(() => {
          this.savedRowId = null;
          this.savedRowClassName = '';
          this.showErrorMessage = false;
        }, 2000);

      });
    },


    rowCallback(item, type) {
      if (!item || type !== 'row') return null
      return item.id === this.savedRowId ? this.savedRowClassName : ''
    },

    clearSearchFilters() {
      this.searchKeyword = "";

      this.searchFromDate = null;
      this.searchToDate = null;
      this.amazonRefunds = [];

      this.total_rows = 0;
      this.current_page = 1;
      this.previousPageLink = null;
      this.nextPageLink = null;
      this.savedRowId = null;
      this.savedRowClassName = '';
    },


    exportToExcel() {
      console.log("Exporting to Excel");
      if (this.searchFromDate == null) {
        alert("Leaving the from date blank will result in a long running query. Please set a date.");
        return;
      }
      this.$store.dispatch("common/showLoader", true);

      django_session.get("erp/amazon/get-refunds/", {
        params: {
          searchKeyword: this.searchKeyword,
          searchFromDate: this.searchFromDate,
          searchToDate: this.searchToDate,
          // isReconciled: this.isReconciled,
          // noSageSalesOrderId: this.noSageSalesOrderId,
          page_size: 0,
        },
      }).then((response) => {
        console.log(response.data);
        let fileName = "Amazon Refunds.xlsx";
        
        // Transform the data into rows, with order items expanded
        const rows = [];
        response.data.forEach(refund => {
          // For each order item, create a row with both order and item details
          rows.push([
            refund.sage_reference_number,
            refund.posted_date,
            refund.order_id,
            refund.product_details[0].quantity,
            refund.product_details[0].sku,
            refund.product_details[0].description,
            refund.product_charges,
            refund.refunded_sales,
            refund.tax,
            refund.amazon_fees,
            refund.digital_services_fee,
            refund.promo_rebates,
            refund.refunded_expenses,
            refund.total_amount,
          ]);
        });
        // Create worksheet with headers

        const ws = utils.aoa_to_sheet([
          [
            // Order level headers
            "CN #",
            "Date", 
            "Order ID", 
            "Qty", 
            "SKU", 
            "Description", 
            "Product Charges", 
            "Refunded Sales", 
            "Tax", 
            "Amazon Fees", 
            "Digital Services Fee", 
            "Promo Rebates", 
            "Refunded Expenses", 
            "Total Amount"
          ],
          ...rows
        ]);

        // Set column widths
        ws['!cols'] = [
          { wch: 15 }, // CN #
          { wch: 15 }, // Date
          { wch: 15 }, // Order ID
          { wch: 15 }, // Qty
          { wch: 15 }, // SKU
          { wch: 40 }, // Description
          { wch: 15 }, // Product Charges
          { wch: 15 }, // Refunded Sales
          { wch: 15 }, // Tax
          { wch: 15 }, // Amazon Fees
          { wch: 15 }, // Digital Services Fee
          { wch: 15 }, // Promo Rebates
          { wch: 15 }, // Refunded Expenses
          { wch: 15 }, // Total Amount
        ];

        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Refunds");
        writeFile(wb, fileName);
      }).catch((error) => {
        console.error("Export failed:", error);
      }).finally(() => {
        this.$store.dispatch("common/showLoader", false);
      });
    },
  },
};
</script>

<style scoped>
.order-details .card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-item:last-child {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.promotion-item {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 4px;
  border-left: 4px solid #6c757d;
}

.totals {
  font-size: 1.5rem;
  font-weight: bold;
}

/* ignore these errors */
/deep/ tr {
  transition: background-color 0.5s ease;
}

/deep/ .highlight-saved-success {
    background-color: rgba(53, 146, 27, 0.685);
}

/deep/ .highlight-saved-danger {
    background-color: rgba(255, 0, 0, 0.685);
}
</style>
